<!-- Original file, please try to conform to the specification -->
<template>
    <div class="wallet-box">
        <div class="wallet-heading">
            <h1>My Wallet</h1>
            <button class="Withdraw-btn" :class="{ 'no-drop': this.totalBalance === '0', 'pointer': this.totalBalance !== '0' }"
                :disabled="this.totalBalance === '0'" @click="goTowithdraw()">Withdraw</button>
        </div>
        <div class="balance-box">
            <div class="col-4 total-balance">
                <h1>
                    Total Balance
                </h1>
                <div class="amount-div">
                    <p class=""><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 52 53"
                            fill="none">
                            <path d="M12.8223 22.1438H21.3704" stroke="#6D4601" stroke-width="3.20555"
                                stroke-linecap="round" stroke-linejoin="round"/>
                            <path
                                d="M47.0096 27.4863C47.0096 27.3218 47.0139 26.3473 47.0096 26.2789C46.9327 25.2082 46.0159 24.3556 44.8619 24.285C44.7871 24.2808 44.6995 24.2808 44.52 24.2808H38.9594C35.1448 24.2808 32.0547 27.1508 32.0547 30.6919C32.0547 34.2329 35.147 37.1029 38.9573 37.1029H44.52C44.6995 37.1029 44.7871 37.1029 44.8619 37.0987C46.0159 37.0281 46.9348 36.1755 47.0096 35.1048C47.0139 35.0364 47.0139 34.0619 47.0139 33.8974"
                                stroke="#6D4601" stroke-width="3.20555" stroke-linecap="round"/>
                            <path
                                d="M38.4652 32.8287C39.6454 32.8287 40.6022 31.872 40.6022 30.6917C40.6022 29.5115 39.6454 28.5547 38.4652 28.5547C37.2849 28.5547 36.3281 29.5115 36.3281 30.6917C36.3281 31.872 37.2849 32.8287 38.4652 32.8287Z"
                                fill="#6D4601" />
                            <path
                                d="M21.3697 47.7881H27.7808C35.8395 47.7881 39.87 47.788 42.3724 45.2835C44.1013 43.5567 44.6377 41.1034 44.8022 37.1029M44.8022 24.2807C44.6355 20.2802 44.1013 17.8269 42.3724 16.1002C39.87 13.5956 35.8395 13.5956 27.7808 13.5956H21.3697C13.3109 13.5956 9.2805 13.5956 6.77804 16.1002C4.27344 18.6026 4.27344 22.6331 4.27344 30.6918C4.27344 38.7505 4.27344 42.781 6.77804 45.2835C8.17352 46.6811 10.0434 47.2987 12.8216 47.5701M12.8216 13.5956L20.8034 8.30214C21.9259 7.572 23.2362 7.18335 24.5752 7.18335C25.9143 7.18335 27.2246 7.572 28.3471 8.30214L36.3289 13.5956"
                                stroke="#6D4601" stroke-width="3.20555" stroke-linecap="round"/>
                        </svg>
                    </p>
                    <div class="amount">{{ this.totalBalance || 0 }} <span class="pk">PKR</span></div>
                </div>
            </div>
            <div class="vl"></div>
            <div class="col-4 total-reward">
                <h1>
                    Total Reward
                </h1>
                <div class="amount-div">
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 43 42" fill="none">
                            <g clip-path="url(#clip0_5095_2432)">
                                <path
                                    d="M28.4199 28C28.4199 26.0662 22.9354 24.5 16.1699 24.5M28.4199 28C28.4199 29.9338 22.9354 31.5 16.1699 31.5C9.40442 31.5 3.91992 29.9338 3.91992 28M28.4199 28V36.6398C28.4199 38.633 22.9354 40.25 16.1699 40.25C9.40442 40.25 3.91992 38.6348 3.91992 36.6398V28M28.4199 28C35.1119 28 40.6699 26.2728 40.6699 24.5V7M16.1699 24.5C9.40442 24.5 3.91992 26.0662 3.91992 28M16.1699 24.5C8.43842 24.5 2.16992 22.7728 2.16992 21V12.25M16.1699 8.75C8.43842 8.75 2.16992 10.3162 2.16992 12.25M2.16992 12.25C2.16992 14.1838 8.43842 15.75 16.1699 15.75C16.1699 17.5228 21.8627 19.25 28.5547 19.25C35.2449 19.25 40.6699 17.5228 40.6699 15.75M40.6699 7C40.6699 5.06625 35.2449 3.5 28.5547 3.5C21.8627 3.5 16.4394 5.06625 16.4394 7M40.6699 7C40.6699 8.93375 35.2449 10.5 28.5547 10.5C21.8644 10.5 16.4394 8.93375 16.4394 7M16.4394 7V24.7905"
                                    stroke="#085576" stroke-width="2.54545" />
                            </g>
                            <defs>
                                <clipPath id="clip0_5095_2432">
                                    <rect width="42" height="42" fill="white" transform="translate(0.419922)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </p>
                    <div class="amount">{{ totalReward || 0 }} <span class="pk">PKR</span></div>
                </div>
            </div>
            <div class="vl" style="height: 80px"></div>
            <div class="col-4 total-refund">
                <h1>
                    Total Refund
                </h1>
                <div class="amount-div">
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 42 42" fill="none">
                            <path
                                d="M20.948 32.9412H9.83031C8.35602 32.9412 6.94211 32.3555 5.89963 31.313C4.85714 30.2706 4.27148 28.8566 4.27148 27.3824V12.5588C4.27148 11.0845 4.85714 9.67062 5.89963 8.62814C6.94211 7.58566 8.35602 7 9.83031 7H32.0656C33.5399 7 34.9538 7.58566 35.9963 8.62814C37.0388 9.67062 37.6244 11.0845 37.6244 12.5588V20.8971M4.27148 16.2647H37.6244M11.6832 25.5294H11.7018M19.095 25.5294H22.8009M28.3597 32.9412H39.4774M28.3597 32.9412L33.9185 27.3824M28.3597 32.9412L33.9185 38.5"
                                stroke="#085576" stroke-width="2.56043" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </p>
                    <div class="amount">{{ totalRefund || 0 }} <span class="pk">PKR</span></div>
                </div>
            </div>
        </div>
        <div class="transaction-table">
            <div class="tran-top">
                <h1>Transaction History</h1>
                <div class="filter">
                    <el-select v-model="value" clearable placeholder="Filter By Type" @change="getHistory()"
                        style="padding-top: 6px; padding-bottom: 6px;">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <div class="demo-date-picker">
                        <div class="block">
                            <el-date-picker v-model="dateSelect" type="daterange" start-placeholder="Start Date"
                                end-placeholder="End Date" :disabled-date="disabledDate" @change="getHistory()" />
                        </div>
                    </div>
                </div>
            </div>
            <el-table :data="tableData" v-loading="loading" style="width: 100%; padding-top: 47px;">
                <el-table-column prop="type" label="Type" width="150" style="background: #F9FBFF;">
                    <template v-slot="scope">
                        {{ scope.row.type }}
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="Date" width="250" style="background: #F9FBFF;">
                    <template v-slot="scope">
                        {{ formatDate(scope.row.created_at) }}
                    </template>

                </el-table-column>
                <el-table-column prop="amount" label="Amount" width="150" style="background: #F9FBFF;">
                    <template v-slot="scope">
                        <div class="amount-cell" style="display: flex; align-items: center;">
                            <!-- Align SVG icon to the left -->
                            <svg v-if="scope.row.type == 'debit'" width="18" height="18" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;">
                                <rect width="20" height="20" rx="10" fill="#FFA507" />
                                <path d="M6 10H14" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                            <svg v-else width="18" height="18" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg" style="margin-right: 5px;">
                                <rect width="20" height="20" rx="10" fill="#22CD85" />
                                <path d="M10 6V14" stroke="white" stroke-width="1.14286" stroke-linecap="round" />
                                <path d="M6 10H14" stroke="white" stroke-width="1.14286" stroke-linecap="round" />
                            </svg>
                            <!-- Align price to the right -->
                            <span style="margin-right: auto; text-align: left;">{{ scope.row.amount }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="ResponseDetails" label="Status" width="150" style="background: #F9FBFF;">
                    <template v-slot="scope">
                        <div v-if="scope.row.type == 'reward'" class="amount-cell">
                            {{ JSON.parse(scope.row.payload).status }}
                        </div>
                        <div v-else class="amount-cell">
                            {{ JSON.parse(scope.row.payload).status }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="transactiondetails" label="Transaction Details" width="380"
                    style="background: #F9FBFF;">
                    <template v-slot="scope">
                        <div class="amount-cell">
                            Trx: {{ scope.row.uid }}
                            <br />
                            {{ scope.row.type == 'debit' ? 'Trx: Bill Payment' : '' }}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="dflex-end">
                <template v-if="this.totalData > 10">
                    <el-pagination background :page-size="this.perPage" class="page-bac" small
                        current-page.sync="this.pageTo" layout="prev, pager, next" :total="this.totalData"
                        @current-change="handleCurrentChange" />
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import php from "@/php/index.js"


export default {
    name: "wallet",
    data() {
        return {
            dateSelect: ["", ""],
            loading: false,
            totalBalance: '',
            totalRefund: '',
            totalReward: '',
            walletDisable: false,
            options: [{
                value: 'reward',
                label: 'REWARD'
            }, {
                value: 'debit',
                label: 'DEBIT'
            }
            ],
            value: '',
            tableData: [],
            perPage: 5,
            totalData: '',
            pageIndex: 0,
            pageTo: 0,
            userInfo: '',
        }
    },
    watch: {
    dateSelect(newVal) {
      if (!newVal || newVal.length === 0) {
        this.getHistory()
      }
    },
  },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.userName = this.userInfo.nickname
        this.userMobile = this.userInfo.mobile
        this.getBalance()
        this.getHistory()

    },
    methods: {
        disabledDate(time) {
            return time.getTime() > Date.now()
        },
        goTowithdraw() {
            window.location.href = '/withdraw'
        },
        async getBalance() {
            try {
                const response = await php.get(`wallet/get?user_id=${this.userInfo.userId}`)
                console.log('response: ', response)
                if (response.data.code === 404) {
                    await this.walletCreate()
                    return 0
                } 
                    this.totalBalance = response.data.data.CurrentBalance
                    this.totalRefund = response.data.data.total_refunds
                    this.totalReward = response.data.data.total_rewards
                    return this.totalBalance
                
            } catch (error) {
                console.error('Error while fetching balance:', error)
                throw error // Propagate the error to the caller
            }
        },
        // Create Wallet Api 
        walletCreate() {
            const payload = {
                user_id: this.userInfo.userId,
                password: "123456",
                password_confirm: "123456"
            }
            php.post('wallet/create', payload).then((response) => {
                this.getBalance()
                console.log('response:', response)
            }).catch((error) => {
                console.log('error:', error)
            })
        },
        
        getHistory(page = 1) {
            this.loading = true
            const payload = {
                "user_id": this.userInfo.userId,
                "type": this.value,
                "from": (this.dateSelect && this.dateSelect[0] && this.dateSelect[0] != '') ? this.dateSelect[0] : '' ,
                "to": (this.dateSelect && this.dateSelect[1]) ? this.dateSelect[1] : '',
                "per_page": this.perPage
            }
            console.log('payload: ', payload)
            php.post(`wallet/transactions/get-transactions?page=${page}`, payload).then((response) => {
                console.log('response:', response.data.data)
                this.tableData = response.data.data.data
                this.perPage = response.data.data.per_page
                this.totalData = response.data.data.total
            }).catch((error) => {
                console.log('error:', error)
            }).finally(() => {
                this.loading = false
            })
        },
        handleCurrentChange(val) {
            this.pageTo = val
            this.getHistory(val)
        },
    },

}
</script>
<style lang="less">
.wallet-box {
    width: 1200px;
    margin: 0 auto;

    i.el-icon {
        color: black;
    }

    .transaction-table {
        .el-select {
            .el-input {
                height: 40px;
            }
        }
    }

    .wallet-heading {
        display: flex;
        justify-content: space-between;
        padding: 39px 0px 12px 0px;

        .pointer {
            cursor: pointer;
        }

        .no-drop {
            cursor: no-drop;
        }

        h1 {
            font-size: 24px;
            font-weight: 500;
            line-height: 42px;
        }
    }

    .Withdraw-btn {
        background-color: #000;
        width: 125px;
        height: 40px;
        border-radius: 50px;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: #FFFFFF;
    }

    .balance-box {
        display: flex;
        flex-direction: row;
        height: 140px;
        border-radius: 12px;
        border: 0.63px solid #6C6A89BF;
        opacity: 0px;
        box-shadow: 0px 30.388492584228516px 43.05036163330078px 0px #D7D7D740;
        align-items: center;

        .col-4 {
            width: 33.33%;

            h1 {
                font-size: 18px;
                font-weight: 400;
                line-height: 36px;
                color: #30404d;
            }
        }

        .amount-div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;

            p {
                padding-top: 5px;
            }

            .amount {
                font-size: 32px;
                font-weight: 500;
                line-height: 56px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 11px;

                span {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 48px;
                    margin-top: 10px;
                }
            }
        }

        .total-balance {
            display: grid;
            justify-content: center;
            width: 50%;
        }

        .total-reward {
            display: grid;
            justify-content: center;
        }

        .total-refund {
            display: grid;
            justify-content: center;
        }

        .vl {
            width: 0px;
            height: 125px;
            border-left: 0.2px solid #7575754d;
            opacity: 0px;
        }
    }

    .transaction-table {
        padding-top: 55px;

        .tran-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h1 {
                font-size: 24px;
                font-weight: 500;
                line-height: 42px;
                color: #000000;
            }

            .filter {
                display: flex;
                flex-direction: row;
                gap: 30px;
                align-items: center;

                .el-select {
                    .select-trigger {
                        width: 125px !important;
                    }
                }
            }
        }

        .dflex-end {
            display: flex;
            justify-content: flex-end;
            margin-top: 12px;
        }

        .amount-cell {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .remove-icon {
            width: 18px;
            height: 18px;
            padding: 0px 6px 4px 6px;
            border-radius: 11px;
            background: #FFA507;
            text-align: center;
            align-content: center;
            color: white;
        }

        .plus-icon {
            width: 18px;
            height: 18px;
            justify-content: center;
            /* Align horizontally */
            align-items: center;
            /* Align vertically */
            padding: 0px 6px 4px 3.7px;
            border-radius: 11px;
            background: #22CD85;
            text-align: center;
            align-content: center;
            color: white;
        }

        .el-input__wrapper {
            box-shadow: none !important;
        }
    }

    .el-table thead {
        height: 56px !important;
        border-radius: 6px !important;
        opacity: 0px;
        border: 1px solid #FAFAFA !important;
    }

    .el-table th.el-table__cell {
        background-color: #F9FBFF !important;
        font-size: 14px !important;
        font-weight: 600 !important;
        line-height: 26px !important;
        color: #4D526D !important;
        text-align: left !important;
        padding: 0px 25px !important;
    }

    .el-table__inner-wrapper {
        border: 1px solid #fafafa !important;
    }

    table.el-table__header {
        width: 100% !important;
    }

    table.el-table__body {
        width: 100% !important;
    }

    .el-range-editor.el-input__wrapper {
        align-items: center;
        display: inline-flex;
        width: 315px !important;
        height: 40px !important;
        border-radius: 6px !important;
        opacity: 0px;
        border: 1px solid #C4C4C4 !important;
    }

    .el-select:focus-visible {
        outline: none !important;
    }

    .item-dropdown {
        outline: none !important;
    }

    .el-select-link {
        outline: none !important;
    }

    .el-select {
        border-bottom: 1px solid #C4C4C4 !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 17.2px !important;
        text-align: left !important;
        padding: 4px 0px !important;
        width: 110px;
    }

    .el-select__wrapper {
        box-shadow: none !important;
        padding: 4px 0px !important;
    }

    tr.el-table__row {
        height: 70px !important;
    }

    .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
        background-color: #F9FBFF !important;
    }

    .el-table .el-table__cell {
        text-align: left !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 29px;
        color: #4D526D;
        padding: 0px 25px !important;
        text-transform: capitalize;
    }
}
</style>
